import styles from './style.module.scss'
import phone from "./assets/phone.png"
import email from "./assets/email.png"
import {useEffect} from "react";


export const Contact = () => {


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <h1 className={styles.topic}>Kontakt</h1>

            <div className={styles.contact_section}>
                <div className={styles.left_part}>
                    <h2>Kamenný obchod ve Veselí nad Moravou:</h2>
                    <div>Karlova 159, 698&nbsp;01&nbsp;Veselí&nbsp;nad&nbsp;Moravou</div>
                    <div>Provozní doba:</div>
                    <div>PO - PÁ od 9:00-13:00, 13:30-17:00</div>
                    <h3>Zákaznické oddělení</h3>
                    <div className={styles.contact_input}>
                        <img src={email} alt={"email icon"}/>
                        e-mail:&nbsp; <a href="mailto:darekjakovysity@seznam.cz">darekjakovysity@seznam.cz</a></div>
                    <div className={styles.contact_input}>
                        <img src={phone} alt={"phone icon"}/>
                        telefon:&nbsp; <a href="tel:00420 702 265 661"> 00420 702 265 661</a></div>
                    <br/>
                    <div>Poptávky na cenové nabídky posílejte</div>
                    <div>na e-mail: buresova@okstavby.cz</div>
                </div>
                <iframe
                    className={styles.map}
                    title={"googleMap"}
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10480.84257734088!2d17.3826084!3d48.9494748!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x54dddaf45cb5e98e!2zRMOhcmVrIGpha28gdnnFoWl0w70!5e0!3m2!1scs!2scz!4v1665999115397!5m2!1scs!2scz"
                    width="600"
                    height="450"
                    allowFullScreen={""}
                    loading={"lazy"}
                    referrerPolicy="no-referrer-when-downgrade">
                </iframe>
            </div>

            <div className={styles.contact_section_inverse}>
                <iframe
                    className={styles.inverse_map}
                    title={"googleMap"}
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2562.581561134973!2d15.772719199999996!3d50.037936099999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470dcc94d3043635%3A0xf2ee92f26fa0b568!2zU2xhZGtvdnNrw6lobyAxOTA2LCA1MzAgMDIgUGFyZHViaWNlIEktWmVsZW7DqSBQxZllZG3Em3N0w60!5e0!3m2!1scs!2scz!4v1700985059475!5m2!1scs!2scz"
                    width="600"
                    height="450"
                    allowFullScreen={""}
                    loading={"lazy"}
                    referrerPolicy="no-referrer-when-downgrade">
                </iframe>
                <div className={styles.left_part}>
                    <h2>Kamenný obchod v Pardubicích:</h2>
                    <div>Sladkovského 1906, 530 02 Pardubice</div>
                    <div>Provozní doba:</div>
                    <div>PO od 9:00-16:00</div>
                    <div>ÚT od 9:00-13:30, 15:30-17:00</div>
                    <div>ST od 9:00-14:00</div>
                    <div>ČT a PÁ od 9:00 - 16:00</div>
                    <h3>Zákaznické oddělení</h3>
                    <div className={styles.contact_input}>
                        <img src={email} alt={"email icon"}/>
                        e-mail:&nbsp; <a href="mailto:darekjakovysity@email.cz">darekjakovysity@email.cz</a></div>
                    <div className={styles.contact_input}>
                        <img src={phone} alt={"phone icon"}/>
                        telefon:&nbsp; <a href="tel:00420 606 124 963"> 00420 606 124 963</a></div>
                    <br/>
                    <div>Poptávky na cenové nabídky posílejte</div>
                    <div>na e-mail: buresova@okstavby.cz</div>
                </div>
            </div>

            <div className={styles.contact_section}>
                <div className={styles.left_part}>
                    <h2>Vyšívací dílna:</h2>
                    <div>Severní I 2907/168,&nbsp;141 00&nbsp;Praha 4 Záběhlice</div>
                    <div>Provozní doba:</div>
                    <div>PO - PÁ od 6:00-15:00</div>
                    <h3>Zákaznické oddělení</h3>
                    <div className={styles.contact_input}>
                        <img src={email} alt={"email icon"}/>
                        e-mail:&nbsp;
                        <a href="mailto:ok.prodej@seznam.cz">
                            ok.prodej@seznam.cz
                        </a>
                    </div>
                    <div className={styles.contact_input}>
                        <img src={phone} alt={"phone icon"}/>
                        telefon:&nbsp;
                        <a href="tel:00420 724 749 071">
                            00420 724 749 071
                        </a>
                    </div>
                    <br/>
                    <div>Poptávky na cenové nabídky posílejte</div>
                    <div>na e-mail: buresova@okstavby.cz</div>
                </div>
                <iframe
                    className={styles.map}
                    title={"googleMap"}
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2562.044055884673!2d14.482083599999998!3d50.048006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b93ba1216f645%3A0xd48e2dce4ad25e8c!2sSevern%C3%AD%20I%202907%2F16%2C%20141%2000%20Praha%204-Z%C3%A1b%C4%9Bhlice!5e0!3m2!1scs!2scz!4v1681377666672!5m2!1scs!2scz"
                    width="600"
                    height="450"
                    allowFullScreen={""}
                    loading={"lazy"}
                    referrerPolicy="no-referrer-when-downgrade">
                </iframe>
            </div>

            <div className={styles.contact_section_inverse}>
                <iframe
                    className={styles.inverse_map}
                    title={"googleMap"}
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10198.811829915465!2d14.5001917!3d50.2788047!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5ff931c339dcc365!2zU2Fsw7NuIFbDvcWhaXZreQ!5e0!3m2!1scs!2scz!4v1665999058014!5m2!1scs!2scz"
                    width="600"
                    height="450"
                    allowFullScreen={""}
                    loading={"lazy"}
                    referrerPolicy="no-referrer-when-downgrade">
                </iframe>
                <div className={styles.left_part}>
                    <h2>Vyšívací studio:</h2>
                    <div>Mělnická 73/106, 277 11 Libiš</div>
                    <div>Provozní doba:</div>
                    <div>PO - ČT od 7:00-12:00, 12:30-15:30</div>
                    <div>PÁ od 7:00 - 15:00</div>
                    <h3>Zákaznické oddělení</h3>
                    <div className={styles.contact_input}>
                        <img src={email} alt={"email icon"}/>
                        e-mail:&nbsp; <a href="mailto:salon.vysivky@seznam.cz">salon.vysivky@seznam.cz</a></div>
                    <div className={styles.contact_input}>
                        <img src={phone} alt={"phone icon"}/>
                        telefon:&nbsp; <a href="tel:00420 602 539 858"> 00420 602 539 858</a></div>
                    <br/>
                    <div>Poptávky na cenové nabídky posílejte</div>
                    <div>na e-mail: buresova@okstavby.cz</div>
                </div>
            </div>
        </div>
    )
};